<template>
  <div class="BGS" >
    <div class="asd" v-for="(item,index) in list" :key="index">
   <div class="List" v-if="item.msg=='评论了你'" >
      <div class="List_Left">
        <img :src="item.to_headimgurl" alt="">
      </div>
      <div class="List_Right">
        <div class="List_Right_T">
          <div class="List_Right_T_left" >
            <div class="name">{{item.to_userinfo}}</div>
            <div class="reply" >评论了你的投诉<span @click="Go_xq(item.ep_id)">《{{item.userComplaints.statement_title}}》</span></div>
          </div>
          <div class="List_Right_T_right">
            <div class="data">{{item.create_time}}</div>
            <img @click.stop="Reply(index)" src="@/assets/img/pinglun_icon.png" alt="">
          </div>
        </div>
        <div class="List_Right_Bom">
          {{item.content}}
        </div>
        <div class="Reply_Comments" v-show="isshow==index">
            <input type="text" class="input" v-model="Input_Value">
            <div class='btn_Reply' @click.stop="Submit(item.id)" >回复</div>
        </div>
      </div>
    </div>

    <div class="give" v-if="item.msg=='点赞了你'">
            <div class="give_Left">
                <img :src="item.headimgurl" alt="">
                <div class="content">
                    <div class="content_top">
                        <span>{{item.nickname}}</span>
                        <span>{{item.create_time}}</span>
                    </div>
                    <div class="content_bottom">
                        赞了你的投诉<span>《{{item.complaintCollection.statement_title}}》</span>
                    </div>
                </div>
            </div>
            <div class="give_Right">
                    <div class="G_xq" @click="Go_xq(item.cid)">查看详情</div>
            </div>
    </div>
    </div>
  </div>
</template>

<script>
    import {news_list,news_save} from "../../api/api.js"
    import Swiper from "swiper";
export default {
   metaInfo(){
     return{
        title: '评论管理',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
        isshow:-1,
        list:[],
        page:1,
        load:true,
        Input_Value: "", //输入框
    };
  },
  methods: {
      Reply(index){
        //console.log(index)
         if(this.isshow ==index){
         this.isshow =-1
      }else{
          this.isshow=index;
      }
      },


      // 回复提交
      Submit(id) {
          let Obj_id = id;  //上级id
          let Input_Value = this.Input_Value; //回复内容
          let url1 = this.url1;
          if (!Input_Value) {
              this.$message.error("回复内容不能为空");
          } else {
              news_save({
                  pid: Obj_id,
                  content: Input_Value,
              }).then((res) => {
                  //console.log(res);
                  if (res.data.code == 200) {
                      this.$message.success(res.data.msg);
                     setTimeout(this.isshow =-1, 100);       
                  } else {
                      this.$message.error(res.data.msg);
                  }
              });
          }
      },


      // 跳转详情页面
      Go_xq(id) {
        //console.log(id)
          this.$router.push({
              path: "/PersonalCenter/CDetails",
              query: { id: id },
          });
      },



      news_list_one(){
          news_list({type:1,page:1}).then((res) => {
              let that = this;
              //console.log(res)
              that.list = res.data.data.data;
          });
      },

      //分页
      news_list_page() {
          let isLoading = true; //是否有数据可以加载
          window.onscroll = async () => {
              // 距离底部200px时加载一次
              let bottomOfWindow =document.documentElement.scrollHeight  -document.documentElement.clientHeight -document.documentElement.scrollTop <=0.25;
              //console.log(bottomOfWindow)

              if (bottomOfWindow && isLoading == true) {
                  this.page = this.page + 1; //每次分页+1
                  news_list({type: 1,page:this.page}).then((res) => {
                      //console.log(res.data.data.data);
                      if (res.data.data.data.length > 0) {
                          this.list.push(...res.data.data.data); //追加数据 使用 ...语法
                          isLoading = true;
                          this.load = true;
                      } else {
                          this.load = false;
                          isLoading = false; //无数据可以加载
                      }
                  });
              }
          };
      },




  },
  mounted() {
      this.news_list_one();
      this.news_list_page();
  },
};
</script>

<style scoped>
.BGS {
  width: 960px;
}
.List {
  padding: 20px;
  cursor: pointer;
  background: #fff;
  margin-left: 20px;
  display: flex;
  border:1px solid #f5f5f5;
}
.List_Right_T {
  width: 840px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.List_Right_T_left {
  display: flex;
  width: 73%;
}
.List_Right_T_right {
  display: flex;
  width: 27%;
}
.List_Right_T_left .name {
  width: 14%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.List_Right_T .reply {
  width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 90px;
}
.List_Right_T .reply span {
  color: #e47b15;
}
.List_Left img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.List_Right_T_right img {
  width: 16px;
  height: 16px;
}
.List_Right_T_right .data {
  margin-right: 50px;
  color: #999999;
  font-size: 12px;
}
.List_Right_Bom {
  width: 840px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Reply_Comments{
    margin-left:20px;
}
.Reply_Comments{
    margin-top:10px;
}
.Reply_Comments .input{
    width:780px;
    padding-left:10px;
    height: 40px;
border: 1px solid #999999;
border-radius: 5px;
}
.btn_Reply{
    margin-top:10px;
    margin-right:50px;
    width: 96px;
height: 30px;
background: #1F72D9;
border-radius: 5px;
text-align:center;
line-height:30px;
color:#fff;
float:right;
}
.give{
     padding: 20px;
  cursor: pointer;
  background: #fff;
  margin-left: 20px;
  display: flex;
  border:1px solid #e5e5e5;
  justify-content: space-between;
  align-items: center;
}
.give_Left{
    display:flex;
}
.give_Left img{
    width:48px;
    height:48px;
    border-radius:50%;
    margin-right:20px;
}
.content .content_bottom{
    margin-top:10px;
}
.content_bottom span{
    color:#E47B15;
}
.G_xq{
     width: 96px;
height: 30px;
background: #1F72D9;
border-radius: 5px;
text-align:center;
line-height:30px;
color:#fff;
}
.content_top span:nth-child(2){
    font-size: 12px;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
margin-left:8px;
}
</style>